import React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";

import styles from "../components/ARWebBilling.module.css";

import "./../assets/css/custom.css";

import i18next from "../constants/i18n";
import { formatCurrency } from "../utils/utils";

let fontStyle1 = {
	temp1: {},
	temp2: {
		fontFamily: "GilroySemiBold !important",
	},
};

const ARWebBilling = (props) => {
	let { totalCartPrice, subTotal, discount, taxValue, tipValue, restTemplate, serviceCharge, themeColors = {} } =
    props;

  const {
    checkoutCardsBgColor = null,
    checkoutBillingPriceFontSize = null,
    checkoutBillingTotalPriceFontSize = null,
    checkoutFontColor = null,
} = themeColors || {};

	let billingList = [
		{
			label: i18next.t("Subtotal"),
			value: subTotal,
		},
		{
			label: i18next.t("Processing Fee"),
			value: serviceCharge,
		},
	];

	if(tipValue) {
    billingList.push({
      label: i18next.t("Tip"),
      value: tipValue,
    })
  }

	return (
		<Grid item mx={props.page == "checkout" ? 2 : 1} pb={3} mt={1.5}>
			<Box
				p={2}
        sx={{backgroundColor : (props.page === "checkout" && checkoutCardsBgColor) ?? "transparent"}}
				className={styles.mainView}
			>
				{billingList.map((x, i) =>
					x?.value > 0 ? (
						<Box key={i} className={styles.listView} mb={1}>
							<Box className={styles.labelView}>
								<Typography
                  className={styles.labelText}
                  sx={{color : `${checkoutFontColor ?? "#000"} !important`}}
                >
                  {x.label}
								</Typography>
							</Box>
							<Typography
                className={styles.billVal}
                sx={{
                  fontSize : `${checkoutBillingPriceFontSize ?? "16"}px`,
                  color : `${checkoutFontColor ?? "#000"} !important`,
                }}
              >
								{/* {currency} */}
								{formatCurrency(x.value)}
							</Typography>
						</Box>
					) : null
				)}

				<Box className={styles.listView}>
					<Box className={styles.listLabelView}>
						<Typography
							className={styles.billTitle}
							sx={{
							  ...fontStyle1[restTemplate],
                color : `${checkoutFontColor ?? "#000"} !important`
              }}
						>
							{i18next.t("Your bill")}
						</Typography>
					</Box>
					<Typography
						className={styles.totalVal}
						sx={{
						  ...fontStyle1[restTemplate],
              color : `${checkoutFontColor ?? "#000"} !important`,
              fontSize : `${checkoutBillingTotalPriceFontSize ?? "19"}px`
						}}
					>
						{/* {currency} */}
						{formatCurrency(totalCartPrice)}
					</Typography>
				</Box>
			</Box>
		</Grid>
	);
};

export default ARWebBilling;
